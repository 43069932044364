.hero-singel-slide {
  position: relative;
}

.hero-area {
  &--style-1 {
    .hero-bg {
      height: 160px;
      @include breakpoint(small) {
        height: 240px;
      }
      img {
        height: 100%;
        border-radius: 5px;
      }
    }

    .inner-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 35px;
      .content {
        width: 60%;
        text-align: center;
        text-transform: uppercase;

        .title-tag {
          text-transform: capitalize;
          font-weight: 400;
          color: $color-radical-red;
        }

        .title {
          color: $color-white;
        }

        .sub-title {
          font-weight: 300;
          letter-spacing: 1.5px;
          font-size: 12px;
          span {
            font-size: $font-size-h1;
            font-weight: 900;
            color: $color-radical-red;
          }
        }
      }

      .product-img {
        width: 40%;
        position: relative;
      }
      .shape {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: -1;
      }
    }

    &.hero-area {
      position: relative;
      .swiper-pagination-bullet {
        background: #383636;
        transition: $transition-base;
        width: 7px;
        height: 7px;
        margin: 0 7px;
        &-active {
          width: 15px;
          background: $color-radical-red;
          border-radius: 3px;
        }
      }

      .swiper-pagination {
        bottom: -25px;
      }
    }
  }

  &--style-2 {
    overflow: hidden;
    border-radius: 10px;
    .hero-bg {
      height: 130px;
      @include breakpoint(small) {
        height: 240px;
      }
      img {
        height: 100%;
        border-radius: 5px;
      }
    }
    .inner-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .content {
        text-align: center;
        text-transform: capitalize;
        color: $color-white;

        .title {
          font-size: 25px;
          font-weight: 900;
        }

        .sub-title {
          font-size: 25px;
          font-weight: 300;
          letter-spacing: 1.5px;
          margin-top: -10px;
        }
      }
    }
  }
}
